/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Button } from '@monbanquet/crumble'
import { graphql } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import { colors, breakpoints } from '../../theme'
import { navigate } from '../Link'
import Img from 'gatsby-image'
import LogoStampBanner from '../../assets/logo-stamp-beige.svg'
import { PercentageIcon } from '../DoubleBanner'
import mailIcon from '../../assets/envelope-regular.svg'

const mailTo = () => {
  const subject = encodeURIComponent('GALETTE DES ROIS 2025')
  const body = encodeURIComponent('')
  const mailtoLink = `mailto:sales@monbanquet.fr?subject=${subject}&body=${body}`
  window.location.href = mailtoLink
}

const Banner = ({
  title,
  subTitle,
  picture,
  link,
  backgroundColor,
  textColor,
  ...props
}) => (
  <StyledBanner
    onClick={() => link && navigate(link, { newTab: true })}
    hasLink={!!link}
    backgroundColor={backgroundColor}
    textColor={textColor}
    {...props}
  >
    <img src={LogoStampBanner} className="logo-stamp-banner" alt="" />
    {picture && (
      <div className="picture">
        <StyledImg fluid={picture.fluid} alt={picture.title} />
      </div>
    )}
    <div className="content-container">
      <div className="content">
        <p className="title">{title}</p>
        <span className="shape"></span>
        <p
          className="description"
          dangerouslySetInnerHTML={{
            __html:
              title === 'GALETTE DES ROIS 2025'
                ? 'Tirez les Rois et les Reines avec vos collaborateurs & régalez vous avec les galettes de nos chefs & artisans !'
                : 'Pour promouvoir une consommation responsable, nous privilégions les partenaires qui respectent l’environnement et favorisent les initiatives durables ou solidaires.',
          }}
        ></p>
        {title === 'GALETTE DES ROIS 2025' && (
          <div className="contact-us">
            <span>Nous contacter</span>
            <img
              className="mail-icon"
              src={mailIcon}
              alt="mail"
              onClick={mailTo}
            />
          </div>
        )}
        <p
          className="description"
          dangerouslySetInnerHTML={{ __html: subTitle }}
        ></p>
      </div>
    </div>
  </StyledBanner>
)

const StyledImg = styled(Img)`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
`

const StyledBanner = styled.section`
  position: relative;
  width: 100%;
  height: 520px;
  display: flex;
  background: ${colors.background.semiLight};
  align-items: center;
  overflow: hidden;

  ${({ hasLink }) => (hasLink ? `cursor: pointer;` : ``)}

  .logo-stamp-banner {
    position: absolute;
    z-index: 1;
    top: 20%;
    left: 37%;
  }

  .picture {
    position: absolute;
    height: 100%;
    left: 0;
    width: 51%;
    align-self: center;
  }

  .mail-icon {
    max-width: 40px;
    cursor: pointer;
    position: relative;
    margin-left: 250px;
    top: -35px;
    padding: 5px;
    border-radius: 30%;
    box-sizing: border-box;
    display: inline-block;
  }

  .content-container {
    width: 70%;
    height: 100%;
    margin-left: auto;
    clip-path: polygon(24% 0, 100% 0, 100% 100%, 0 100%);
    padding: 40px 0;
    position: relative;
    display: flex;
    background-color: ${props => props.backgroundColor};

    .content {
      max-width: 700px;
      margin: auto 204px auto auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: ${props => props.textColor || colors.brand.beige};
      text-align: center;

      .percentage {
        font-family: Centra Bold;
        font-size: 100px;
        line-height: 80px;
        display: flex;

        .percentage-icon {
          height: 55px;
          width: 55px;
        }
      }
      .title {
        margin-top: 7px;
        font-family: Bely;
        font-size: 50px;
        text-transform: uppercase;
      }

      .contact-us {
        font-size: 25px;
        margin-top: 50px;
        font-family: Bely;
        text-transform: none;
      }

      .shape {
        width: 23px;
        height: 29px;
        background-color: ${colors.brand.beige};
        clip-path: polygon(75% 0, 100% 0, 25% 100%, 0 100%);
        margin: 37px auto;
      }
      .description {
        color: ${props => props.textColor || colors.brand.black};
        font-size: 20px;
      }
    }
  }

  @media (min-width: 2300px) {
    .content-container {
      clip-path: polygon(22% 0, 100% 0, 100% 100%, 0 100%);
    }
  }

  @media (max-width: 1800px) {
    .logo-stamp-banner {
      left: 36%;
    }
  }

  @media (max-width: 1500px) {
    .logo-stamp-banner {
      left: 33%;
    }
    .content-container {
      width: 75%;
      clip-path: polygon(25% 0, 100% 0, 100% 100%, 0 100%);

      .content {
        margin-right: 120px;
      }
    }
  }

  @media (max-width: 1400px) {
    .logo-stamp-banner {
      left: 30%;
    }
    .content-container {
      width: 80%;
      clip-path: polygon(30% 0, 100% 0, 100% 100%, 0 100%);

      .content {
        margin-right: 100px;
      }
    }
  }

  @media (max-width: 1200px) {
    .logo-stamp-banner {
      left: 29%;
    }
    .content-container {
      width: 81%;
      clip-path: polygon(31% 0, 100% 0, 100% 100%, 0 100%);

      .content {
        margin-right: 60px;
      }
    }
  }

  @media (max-height: 810px) and (min-width: ${breakpoints.desktop}px) {
    .content-container {
      padding: 45px 0 44px;

      .content {
        .percentage {
          line-height: 60px;

          .percentage-icon {
            height: 57px;
            width: 57px;
          }
        }
        .title {
          font-size: 33px;
        }
        .shape {
          margin: 30px auto;
        }
      }
    }
  }

  @media (max-width: ${breakpoints.desktop}px) {
    flex-direction: column;

    .logo-stamp-banner {
      width: 90px;
      height: 90px;
      position: absolute;
      z-index: 1;
      top: 30%;
      left: 65%;
    }

    .picture {
      position: unset;
      height: 300px;
      width: 100%;
    }

    .content-container {
      width: 100%;
      clip-path: polygon(0 10%, 100% 0, 100% 100%, 0 100%);
      position: absolute;
      top: 200px;
      padding: 50px 30px;
      display: block;

      .content {
        max-width: 700px;
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: ${colors.brand.beige};
        text-align: center;

        .percentage {
          font-family: Centra Bold;
          font-size: 65px;
          line-height: 60px;

          .percentage-icon {
            height: 38px;
            width: 33px;
          }
        }
        .title {
          font-family: Bely;
          font-size: 18px;
          text-transform: uppercase;
        }
        .shape {
          width: 17px;
          height: 20px;
          background-color: ${colors.brand.beige};
          clip-path: polygon(75% 0, 100% 0, 25% 100%, 0 100%);
          margin: 16px auto;
        }
        .description {
          font-size: 14px;
        }
      }
    }
  }
`

export default Banner
export { StyledBanner }

export const BannerFragment = graphql`
  fragment BannerFragment on ContentfulBanner {
    title
    subTitle
    backgroundColor
    textColor
    picture {
      title
      fluid(maxWidth: 900, quality: 90) {
        # tracedSVG
        aspectRatio
        src
        srcSet
        sizes
        srcWebp
        srcSetWebp
      }
    }
  }
`
